import React from 'react';

import Layout from '../components/layout/Layout';


const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export/en/social-responsability`,

  },


];

const seo={
  title: "Nuestra política de derechos humanos",
  desc:  "Descubre nuestras políticas de derechos humanos",
  image: "",
  pathname: "/politica-derechos-humanos/",
}

 const DerechosHumanos = ({location}) =>  (

  <Layout location={location} crumbLabel="Politica de derechos humanos"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>


<section id="" className="p-10">


    <div className="container font-serif">
        <h1 className="text-lg font-bold">Nuestras Políticas de Derechos Humanos</h1>
    <p>• Unilimpio y todas las partes interesadas se adhieren estrictamente al respeto de los derechos humanos.<br/>
•	Unilimpio promueve la Seguridad y Salud ocupacional a los colaboradores y todas las partes interesadas. <br/> 
•	Unilimpio rechaza la discriminación en el empleo y la ocupación a nivel general.<br/>
•	Unilimpio ofrece oportunidades de trabajo a todas las personas sin discriminación de nacionalidad, cultura, género, edad, estado civil, raza, asegurando que nuestros procesos se basan en un perfil profesional.<br/>
•	Unilimpio respeta la opinión y libertad de expresión de todas las partes interesadas (Colaboradores, Accionistas, Proveedores, Clientes, Comunidad). <br/>
•	Unilimpio rechaza totalmente el trabajo forzoso y cualquier forma de esclavitud y exige su cumplimiento a todo nivel. <br/>
•	Unilimpio tiene una Declaración firmada, en la cual menciona que respeta todos los derechos de las personas, en forma individual y/o colectiva. <br/>

</p>



    </div>



</section>

  

  </Layout>
);

export default DerechosHumanos;
